import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Chart, Area, Line } from "bizcharts";

import "./AreaChart.css";

export default function Labelline(props) {
  const {
    lineColor,
    firstGradientColor,
    secondaryGradientColor,
    thirdGradientColor,
    data,
    tooltipLabelX,
    tooltipLabelY,
    cardTitle,
    cardTitleColor,
  } = props;

  const scale = {
    attendees: {
      min: 0,
      nice: true,
    },
    hour: {
      range: [0, 1],
    },
  };

  return (
    <Paper>
      <Grid container item xs={12} sm={12} md={12}>
        <Grid container item xs={10} sm={11} md={11}>
          <h1 className="cardTitleChart" style={{ color: cardTitleColor }}>
            {cardTitle}
          </h1>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        className="areaChartContainer"
      >
        <Chart
          scale={scale}
          height={220}
          padding={[5, 30, 55, 50]}
          data={data}
          animate={{
            enter: {
              animation: 'fadeIn',
              delay: 3000,
              duration: 3000,
            },
            appear: {
              animation: 'fadeIn',
              delay: 3000,
              duration: 3000,
            },
            update: {
              animation: 'fadeIn',
              delay: 3000,
              duration: 3000,
            },
            leave: {
              animation: 'fadeIn',
              delay: 3000,
              duration: 3000,
            },
          }}
          autoFit
        >
          <Area
            animate={{
              enter: {
                delay: 800,
                duration: 1000,
              },
              appear: {
                delay: 800,
                duration: 1000,
              },
              update: {
                delay: 800,
                duration: 1000,
              },
              leave: {
                delay: 800,
                duration: 1000,
              },
            }}
            position={`${tooltipLabelX}*${tooltipLabelY}`}
            color={`l (270) 0:${firstGradientColor} .4:${secondaryGradientColor} 1:${thirdGradientColor}`}
          />
          <Line
            animate={{
              enter: {
                delay: 800,
                duration: 1000,
              },
              appear: {
                delay: 800,
                duration: 1000,
              },
              update: {
                delay: 800,
                duration: 1000,
              },
              leave: {
                delay: 800,
                duration: 1000,
              },
            }}
            position={`${tooltipLabelX}*${tooltipLabelY}`}
            color={lineColor}
          />
        </Chart>
      </Grid>
    </Paper>
  );
}
