import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useTimer from "../../hooks/useTimer/useTimer";
import messages from "../../constants/messages/eventCommander";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  phoneRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const LocalTime = (props) => {
  const classes = useStyles();
  const { iconColor, labelColor } = props;
  const [timestamp, setTimestamp] = useState("");

  const phoneResolution = useMediaQuery("(max-width: 480px)");

  const onTick = useCallback(
    (now) => {
      setTimestamp(now.format("h:mm a [GMT] Z"));
    },
    [setTimestamp]
  );
  useTimer({ onTick });

  return (
    <div className={phoneResolution ? classes.phoneRoot : classes.root}>
      <AccessTimeIcon
        fontSize="small"
        className="timeIcon"
        style={{ color: iconColor }}
      />
      <span className="eventCommanderTime" style={{ color: labelColor }}>
        {`${messages.en.localTime} ${timestamp}`}
      </span>
    </div>
  );
};

export default LocalTime;
