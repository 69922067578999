import axios from "axios";

import { history } from "../App";
import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from "../constants/actionTypesRedux/actionTypes";
import { loginURL } from "../constants/urls/urls";

export function loginLoading() {
  return {
    type: LOGIN_LOADING,
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error: error,
  };
}

export function authUser(token) {
  return (dispatch) => {
    dispatch(loginLoading());
    axios({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      url: loginURL,
      data: {
        token,
      },
    })
      .then((response) => {
        sessionStorage.setItem("currentUserToken", response.data.auth_token);
        const { event_uri } = JSON.parse(
          atob(response.data.auth_token.split(".")[1])
        );
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data.auth_token,
        });
        return history.push(`/events/${event_uri}`);
      })
      .catch((error) => error.message && dispatch(loginError(error.message)));
  };
}
