import { createStore, applyMiddleware, combineReducers } from "redux";
import event from "../reducers/eventCommander";
import meetings from "../reducers/meetingsCommander";
import login from "../reducers/login";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const reducer = combineReducers({
  login,
  event,
  meetings,
});

export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
