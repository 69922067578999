import axios from "axios";

import { history } from "../App";
import {
  EVENT_LOADING,
  EVENT_ERROR,
  GET_EVENT,
  GET_EVENT_ACTIVITIES,
  GET_ACTIVITIES_LINKS,
  GET_ACTIVITIES_SPEAKERS,
  GET_CHECKED_IN_ATTENDEES,
  GET_EVENT_BUSINESS_CONFERENCES,
} from "../constants/actionTypesRedux/actionTypes";
import { getEventsURL } from "../constants/urls/urls";

export function eventLoading() {
  return {
    type: EVENT_LOADING,
  };
}

export function eventError(error) {
  return {
    type: EVENT_ERROR,
    error: error,
  };
}

export function getEvent(eventUri, jwtToken, meetingsView) {
  return (dispatch) => {
    dispatch(eventLoading());
    axios
      .get(`${getEventsURL}/${eventUri}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      })
      .then((response) => {
        dispatch({
          type: GET_EVENT,
          payload: response.data.data,
        });
      })
      .then(() =>
        meetingsView
          ? dispatch(getBusinessConferences(eventUri, jwtToken, meetingsView))
          : dispatch(getListOfActivities(eventUri, jwtToken))
      )
      .catch((error) => error.message && dispatch(eventError(error.message)));
  };
}

export function getListOfActivities(eventUri, jwtToken, paginatorURL) {
  const getActivities = `${getEventsURL}/${eventUri}/workshops?include=speakers&offset_to_utc=true&page[size]=3&page[number]=1`;
  let urlToFetch = paginatorURL ? paginatorURL : getActivities;
  return (dispatch) => {
    dispatch(eventLoading());
    axios
      .get(urlToFetch)
      .then((response) => {
        dispatch({
          type: GET_EVENT_ACTIVITIES,
          payload: response.data.data,
        });
        dispatch({
          type: GET_ACTIVITIES_SPEAKERS,
          payload: response.data.included,
        });
        dispatch({
          type: GET_ACTIVITIES_LINKS,
          payload: response.data.links,
        });
      })
      .then(() => dispatch(getCheckedInAttendees(eventUri, jwtToken)))
      .catch((error) => error.message && dispatch(eventError(error.message)));
  };
}

export function getCheckedInAttendees(eventUri, jwtToken) {
  return (dispatch) => {
    dispatch(eventLoading());
    axios
      .get(`${getEventsURL}/${eventUri}/attendees?only_checked_in=true&only_confirmed=true`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      })
      .then((response) => {
        dispatch({
          type: GET_CHECKED_IN_ATTENDEES,
          payload: response.data,
        });
      })
      .then(() => dispatch(getBusinessConferences(eventUri, jwtToken)))
      .catch((error) => error.message && dispatch(eventError(error.message)));
  };
}

export function getBusinessConferences(eventUri, jwtToken, meetingsView) {
  return (dispatch) => {
    dispatch(eventLoading());
    axios
      .get(`${getEventsURL}/${eventUri}/business_conferences`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      })
      .then((response) => {
        dispatch({
          type: GET_EVENT_BUSINESS_CONFERENCES,
          payload: response.data,
        });
      })
      .then(() => (meetingsView ? null : history.push(`/events/${eventUri}`)))
      .catch((error) => error.message && dispatch(eventError(error.message)));
  };
}
