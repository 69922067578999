import React from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { START_STREAM } from '../../constants/actionTypesRedux/actionTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "12px",
    color: "white",
    backgroundColor: "#22D2B9",
    textTransform: "none",
    alignSelf: "center",
    marginLeft: 24,
  },
}));

const StudioStartButton = ({ broadcast }) => {
  const classes = useStyles();

  const signalStart = () => {
    broadcast(START_STREAM, {});
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      className={classes.root}
      onClick={signalStart}
    >
      Signal start
    </Button>
  );
};

export default StudioStartButton;
