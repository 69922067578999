import {
  EVENT_LOADING,
  EVENT_ERROR,
  GET_EVENT,
  GET_EVENT_ACTIVITIES,
  COUNT_ACTIVITY_ATTENDEES,
  COUNT_EVENT_ATTENDEES,
  GET_ACTIVITIES_LINKS,
  GET_ACTIVITIES_SPEAKERS,
  NETWORKING_STATS,
  GET_CHECKED_IN_ATTENDEES,
  GET_EVENT_BUSINESS_CONFERENCES,
} from "../constants/actionTypesRedux/actionTypes";

export const eventInitialState = {
  loading: false,
  eventData: {},
  eventAttendeesOnline: 0,
  activitiesData: { data: [], links: [], attendees: {}, speakers: [] },
  checkedInAttendees: {},
  businessConferencesData: {},
  networkingData: { meetingsLive: 0, AttendeesInMeetings: 0 },
  success: false,
  error: null,
};

export default function eventReducer(state = eventInitialState, action) {
  switch (action.type) {
    case EVENT_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case EVENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };

    case GET_EVENT:
      return {
        ...state,
        loading: false,
        eventData: action.payload,
        success: true,
      };

    case COUNT_EVENT_ATTENDEES:
      return {
        ...state,
        loading: false,
        eventAttendeesOnline: action.payload,
        success: true,
      };

    case GET_EVENT_ACTIVITIES:
      return {
        ...state,
        loading: false,
        activitiesData: {
          ...state.activitiesData,
          data: action.payload,
        },
        success: true,
      };

    case COUNT_ACTIVITY_ATTENDEES:
      return {
        ...state,
        loading: false,
        activitiesData: {
          ...state.activitiesData,
          attendees: {
            ...state.activitiesData.attendees,
            [action.channel]: action.payload,
          },
        },
        success: true,
      };

    case GET_ACTIVITIES_LINKS:
      return {
        ...state,
        loading: false,
        activitiesData: {
          ...state.activitiesData,
          links: action.payload,
        },
        success: true,
      };

    case GET_ACTIVITIES_SPEAKERS:
      return {
        ...state,
        loading: false,
        activitiesData: {
          ...state.activitiesData,
          speakers: action.payload,
        },
        success: true,
      };

    case GET_CHECKED_IN_ATTENDEES:
      return {
        ...state,
        loading: false,
        checkedInAttendees: action.payload,
        success: true,
      };

    case GET_EVENT_BUSINESS_CONFERENCES:
      return {
        ...state,
        loading: false,
        businessConferencesData: action.payload,
        success: true,
      };

    case NETWORKING_STATS:
      return {
        ...state,
        loading: false,
        networkingData: {
          ...state.networkingData,
          meetingsLive: action.payload.live_meetings,
          AttendeesInMeetings: action.payload.live_attendees,
        },
        success: true,
      };

    default:
      return state;
  }
}
