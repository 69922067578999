export const backgroundColor = "#F6F6FA";

export const mainPurple = "#851486";
export const secondaryPurple = "#761D77";
export const thirdPurple = "#611862";

export const mainGray = "#555555";
export const secondaryGray = "#736A73";
export const thirdGray = "#D4D3D4";

export const mainTurquoise = "#22D2B9";
export const secondaryTurquoise = "#2AD2B9";
export const thirdTurquoise = "#2ECC9C";

export const mainAquamarine = "#23DBC1AD";

export const mainWhite = "#FFFFFF";
export const secondaryWhite = "#25EFD300";
export const thirdWhite = "#EFEFF4";

export const mainYellow = "#F4AC33";
export const secondaryYellow = "#F8C633";

export const mainRed = "#F23D4F";

export const mainBlack = "#000000";