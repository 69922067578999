const baseURL = process.env.REACT_APP_EVENTTIA_HOST || "https://connect.eventtia.com";

//socket
export const SOCKET_URL = process.env.REACT_APP_IRIDIUM_HOST || "wss://iridium.eventtia.com/user-socket";

//login
export const loginURL = `${baseURL}/en/api/v3/sso-auth`;

//events
export const getEventsURL = `${baseURL}/api/v3/events`;
