export default {
  en: {
    liveEventAnalyticsTitle: "Live Event Analytics",
    eventWithNoName: "Ipsum Event",
    localTime: "Local time is ",
    meetingsLiveNowLabel: "View live meetings",
    meetingsFilterMenuLabels: {
      allMeetings: "All",
      inProgressMeetings: "In progress",
      waitingMeetings: "Waiting for attendee",
    },
    meetingScheduledByLabel: "Meeting scheduled by",
    noMeetingsLiveMessage: "There are no live meetings at the moment",
  },  
};
