import React from "react";
import { useSelector } from "react-redux";

import SmallCard from "../cards/SmallCard";
import messages from "../../constants/messages/eventCommander";
import {
  secondaryGray,
  mainTurquoise,
} from "../../constants/colors/eventtiaColors";

export default function AttendeesCard() {
  const eventData = useSelector((state) => state.event.eventData);
  const eventAttendeesOnline = useSelector(
    (state) => state.event.eventAttendeesOnline
  );
  const attendancePercentage = (attendeesRegistered, attendeesOnline) => {
    let attendancePercentage = (attendeesOnline / attendeesRegistered) * 100;
    let attendancePercentageRounded =
      attendancePercentage > 100 ? 100 : Math.round(attendancePercentage);
    return attendeesRegistered === 0 ? 0 : attendancePercentageRounded;
  };

  return (
    <SmallCard
      rightSide={true}
      titleLeftSide={eventAttendeesOnline}
      labelLeftSide={messages.en.attendeesOnline}
      titleRightSide={`${attendancePercentage(
        eventData.attributes.total_attendees,
        eventAttendeesOnline
      )}%`}
      labelRightSide={messages.en.attendance}
      colorLeftBar={mainTurquoise}
      colorTitleLeftSide={mainTurquoise}
      colorLabelLeftSide={secondaryGray}
      colorTitleRightSide={mainTurquoise}
      colorLabelRightSide={secondaryGray}
    />
  );
}
