import { useEffect } from "react";
import useChannel from "../../hooks/useChannel/useChannel";

const ChannelConnection = ({
  topic,
  status,
  businessConferenceId,
  setBroadcasts,
}) => {
  const broadcast = useChannel(topic, status, businessConferenceId);

  useEffect(() => {
    setBroadcasts((prevState) => ({
      ...prevState,
      [topic]: broadcast,
    }));

    return () => {
      setBroadcasts((prevState) => {
        const { [topic]: toDelete, ...otherBroadcasts } = prevState;
        return otherBroadcasts;
      });
    };
  }, [topic, broadcast, setBroadcasts]);

  return null;
};

export default ChannelConnection;
