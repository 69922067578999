import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import "./SmallCard.css";

export default function SmallCard(props) {
  const {
    colorLeftBar,
    rightSide,
    titleLeftSide,
    labelLeftSide,
    titleRightSide,
    labelRightSide,
    colorTitleLeftSide,
    colorLabelLeftSide,
    colorTitleRightSide,
    colorLabelRightSide,
  } = props;

  return (
    <>
      <Paper
        className="smallCard"
        style={{ borderLeftColor: colorLeftBar }}
        elevation={3}
      >
        <Grid container item xs={12} sm={12} md={12} style={{ paddingTop: "8%" }}>
          <Grid item xs={rightSide ? 7 : 12} className="smallCardLeftSideContainer">
            <p style={{color: colorTitleLeftSide}} className="smallCardTitle">{titleLeftSide}</p>
            <span style={{color: colorLabelLeftSide}} className="smallCardLabel">{labelLeftSide}</span>
          </Grid>
          {rightSide ? (
            <>
              <Divider
                orientation="vertical"
                flexItem
                style={{ height: "30px", marginTop: "5%", marginRight: "5%" }}
              />
              <Grid style={{ paddingLeft: "4%" }} item xs={4} sm={4} md={4}>
                <p style={{color: colorTitleRightSide}} className="smallCardTitle">{titleRightSide}</p>
                <span style={{color: colorLabelRightSide}} className="smallCardLabel">{labelRightSide}</span>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
}
