import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SocketContext from "../../contexts/SocketContext/SocketContext";
import {
  COUNT_ACTIVITY_ATTENDEES,
  COUNT_EVENT_ATTENDEES,
  NETWORKING_STATS,
  MEETINGS_INFO,
  MEETING_STATUS,
} from "../../constants/actionTypesRedux/actionTypes";
import {
  countActivityAttendees,
  countEventAttendees,
  networkingStats,
  getMeetingsInfo,
  getMeetingStatus,
} from "../../actions/eventSocket";

const mustJoinChannelWarning = () => () => {
  console.error(
    "useChannel broadcast function cannot be invoked before the channel has been joined"
  );
};

const useChannel = (channelTopic, status, businessConferenceId) => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [broadcast, setBroadcast] = useState(mustJoinChannelWarning);

  useEffect(() => {
    const channel =
      (status !== undefined )
        ? socket.channel(channelTopic, {
            client: "browser",
            status: status ? status : null,
          })
        : socket.channel(channelTopic, {
            client: "browser",
            conference_id: businessConferenceId,
          });

    if (channelTopic) {
      channel.onMessage = (event, payload) => {
        switch (event) {
          case COUNT_ACTIVITY_ATTENDEES:
            dispatch(countActivityAttendees(payload.attendees, channelTopic));
            break;
          case COUNT_EVENT_ATTENDEES:
            dispatch(countEventAttendees(payload.attendees));
            break;
          case NETWORKING_STATS:
            dispatch(networkingStats(payload));
            break;
          case MEETINGS_INFO:
            dispatch(getMeetingsInfo(payload));
            break;
          case MEETING_STATUS:
            dispatch(getMeetingStatus(payload));
            break;
          default:
            break;
        }
        return payload;
      };

      channel
        .join()
        .receive("ok", () => {
          setBroadcast(() => channel.push.bind(channel));
        })
        .receive("error", (reason) =>
          console.log("failed to join channel", reason)
        );
    }

    return () => {
      channel.leave();
      setBroadcast(mustJoinChannelWarning);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelTopic, socket, dispatch]);

  return broadcast;
};

export default useChannel;
