import axios from "axios";
import normalize from "json-api-normalizer";

import {
  MEETINGS_INFO_LOADING,
  MEETINGS_STATUS_LOADING,
  MEETINGS_ERROR,
  GET_MEETINGS_LIVE,
  GET_MEETING_FETCHED,
  CLEAN_MEETINGS_DATA,
} from "../constants/actionTypesRedux/actionTypes";
import { getEventsURL } from "../constants/urls/urls";

export function meetingsLoading(meetingsInfo) {
  if (meetingsInfo) {
    return {
      type: MEETINGS_INFO_LOADING,
    };
  } else {
    return {
      type: MEETINGS_STATUS_LOADING,
    };
  }
}

export function meetingsError(error) {
  return {
    type: MEETINGS_ERROR,
    error: error,
  };
}

export function cleanMeetingsData() {
  return {
    type: CLEAN_MEETINGS_DATA,
  };
}

export function getMeetingsLive(
  meetingsIdsArray,
  initialStatus,
  businessConferenceId,
  eventUri,
  jwtToken,
  attendeesWaiting,
  meetingsInfo
) {
  let meetingsIdString;
  if (Array.isArray(meetingsIdsArray)) {
    meetingsIdString = meetingsIdsArray.join();
  } else if (typeof meetingsIdsArray === "string") {
    meetingsIdString = meetingsIdsArray;
  } else {
    meetingsIdString = Object.keys(meetingsIdsArray).join();
  }
  if (meetingsIdsArray.length > 0) {
    return (dispatch) => {
      dispatch(meetingsLoading(meetingsInfo));
      axios({
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        url: `${getEventsURL}/${eventUri}/business_conferences/${businessConferenceId}/business_conference_meetings?meeting_ids=${meetingsIdString}`,
      })
        .then((response) => {
          let normalizedData = normalize(response.data);
          Object.values(normalizedData.businessConferenceMeetings).forEach(
            (meeting) => {
              normalizedData.businessConferenceMeetings[meeting.id][
                "status"
              ] = initialStatus;
            }
          );
          Object.values(normalizedData.businessConferenceParticipants).forEach(
            (participant) => {
              if (attendeesWaiting) {
                attendeesWaiting.includes(participant.attributes.attendeeId)
                  ? (normalizedData.businessConferenceParticipants[
                      participant.id
                    ]["waiting"] = true)
                  : (normalizedData.businessConferenceParticipants[
                      participant.id
                    ]["waiting"] = false);
              }
            }
          );
          if (typeof meetingsIdsArray === "string") {
            let meeting = Object.values(
              normalizedData.businessConferenceMeetings
            )[0];
            let host = Object.values(
              normalizedData.businessConferenceParticipants
            )[0];
            let participant = Object.values(
              normalizedData.businessConferenceParticipants
            )[1];

            dispatch({
              type: GET_MEETING_FETCHED,
              meeting,
              host,
              participant,
            });
          } else {
            dispatch({
              type: GET_MEETINGS_LIVE,
              payload: normalizedData,
            });
          }
        })
        .catch(
          (error) => error.message && dispatch(meetingsError(error.message))
        );
    };
  } else {
    return (dispatch) => {
      dispatch(cleanMeetingsData());
      dispatch(meetingsError("None meetings in progress"));
    };
  }
}
