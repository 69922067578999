import React, { useState } from "react";
import {
  Chart,
  Interval,
  Annotation,
  Coordinate,
  Legend,
  View,
} from "bizcharts";
import DataSet from "@antv/data-set";

export default function CircularChart(props) {
  const {
    chartLabel,
    userData,
    firstColorChart,
    secondColorChart,
    thirdColorChart,
    chartTitleColor,
    chartLabelColor,
  } = props;

  const [meetingsPercentage, setMeetingsPercentage] = useState("");

  const { DataView } = DataSet;

  const userDv = new DataView();
  userDv.source(userData).transform({
    type: "percent",
    field: "value",
    dimension: "type",
    as: "percent",
  });

  const roundMeetingsPercentage = (event) => {
    let percentage = event.data.data.percent;
    let percentageRounded = Math.round(percentage * 100);
    setMeetingsPercentage(`${percentageRounded}%`);
  };

  return (
    <Chart
      placeholder={false}
      height={150}
      autoFit
      onIntervalMouseenter={(e) => roundMeetingsPercentage(e)}
      onIntervalClick={(e) => roundMeetingsPercentage(e)}
    >
      <Legend visible={false} />
      <View
        data={userDv.rows}
        scale={{
          percent: {
            formatter: (val) => {
              return (val * 100).toFixed(2) + "%";
            },
          },
        }}
      >
        <Coordinate type="theta" innerRadius={0.9} />
        <Interval
        // tooltip={false}
          position="percent"
          adjust="stack"
          color={["type", [firstColorChart, secondColorChart, thirdColorChart]]}
        />
        <Annotation.Text
          position={["50%", "45%"]}
          content={meetingsPercentage}
          style={{
            fontFamily: "Montserrat",
            fill: chartTitleColor,
            fontSize: "27",
            textAlign: "center",
          }}
        />
        <Annotation.Text
          position={["50%", "60%"]}
          content={chartLabel}
          style={{
            fontFamily: "Lato",
            fill: chartLabelColor,
            fontSize: "12",
            textAlign: "center",
          }}
        />
      </View>
    </Chart>
  );
}
