import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import EventCommander from "./views/eventCommander/EventCommander";
import MeetingsLive from "./views/meetingsLive/MeetingsLive";
import SocketProvider from "./components/SocketProvider/SocketProvider";

export function AuthenticatedRoutes(props) {
  return (
    <SocketProvider>
      <Router history={props.history}>
        <div>
          <Switch>
            <Route path="/events/:event_uri" exact component={EventCommander} />
            <Route
              path="/events/:event_uri/meetingsLive" exact component={MeetingsLive}
            />
            <Route path="/" exact component={EventCommander} />
          </Switch>
        </div>
      </Router>
    </SocketProvider>
  );
}
