import React from "react";
import { createBrowserHistory as createHistory } from "history";
import { useSelector } from "react-redux";
import { backgroundColor } from "./constants/colors/eventtiaColors";
import { AuthenticatedRoutes } from "./authenticatedRoutes";
import { UnauthenticatedRoutes } from "./unauthenticatedRoutes";

export const history = createHistory();

function App() {
  const currentUser = sessionStorage.getItem("currentUserToken");
  const jwt = useSelector((state) => state.login.jwtToken);
  return (
    <div className="App" style={{ backgroundColor: backgroundColor }}>
      {currentUser || jwt ? (
        <AuthenticatedRoutes history={history} />
      ) : (
        <UnauthenticatedRoutes history={history} />
      )}
    </div>
  );
}

export default App;
