import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";

const styles = (theme) => ({
  customBadge: {
    backgroundColor: (props) => props.color,
    color: "white",
  },
});

function SimpleBadge(props) {
  const { classes, avatarImg, altImg } = props;
  return (
    <div>
      <Badge
        classes={{ badge: classes.customBadge }}
        badgeContent={""}
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
      >
        <Avatar flexItem alt={altImg} src={avatarImg} className="avatar" />
      </Badge>
    </div>
  );
}

export const AvatarBadge = withStyles(styles)(SimpleBadge);
