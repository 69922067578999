import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./LongCard.css";
import { AvatarBadge } from "../avatarBadge/AvatarBadge";

export default function LongCard(props) {
  const {
    titleCard,
    titleButtonLabel,
    titleButtonColor,
    firstAvatarUrl,
    firstAvatarTitle,
    firstAvatarMainLabel,
    firstAvatarSecondLabel,
    firstAvatarImg,
    firstAvatarStatusColor,
    secondAvatarUrl,
    secondAvatarTitle,
    secondAvatarMainLabel,
    secondAvatarSecondLabel,
    secondAvatarImg,
    secondAvatarStatusColor,
    firstDateLabel,
    secondDateLabel,
    // dotsIconColor,
  } = props;

  const phoneResolution = useMediaQuery("(max-width: 480px)");
  const tabletResolution = useMediaQuery(
    "(min-width: 481px) and (max-width: 768px)"
  );

  return (
    <>
      {phoneResolution || tabletResolution ? (
        <Paper className="longCardContainer" style={{ borderLeftColor: titleButtonColor, marginTop: "4%" }}>
          <Grid container item xs={12} sm={12} className="titleContentContainer">
            <Grid item xs={11} sm={11}>
              <span className="titleCard">{titleCard}&nbsp;&nbsp;</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} className="threeDotsContainer">
            <span className="dateLabel">{`${firstDateLabel} - ${secondDateLabel}`}</span>
          </Grid>
          <Grid item xs={12} sm={12} className="longCardDividerContainer">
            <Divider />
          </Grid>
          <Grid container item xs={12} sm={12} className="avatarsMainContainer">
            <Grid container item xs={12} sm={6} className="avatarMainContainer">
              <a
                className="avatarUrlLongCard"
                href={firstAvatarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Grid item xs={3} sm={3} className="avatarContainer">
                  <AvatarBadge
                    avatarImg={firstAvatarImg}
                    color={firstAvatarStatusColor}
                    altImg={firstAvatarMainLabel}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={9}
                  sm={9}
                  className="avatarLabelsContainer"
                >
                  <Grid item xs={12} sm={12}>
                    <span className="avatarTitle">
                      {firstAvatarTitle.length > 25
                        ? firstAvatarTitle.substring(0, 25) + "..."
                        : firstAvatarTitle}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span className="avatarLabel">
                      {firstAvatarMainLabel.length > 25
                        ? firstAvatarMainLabel.substring(0, 25) + "..."
                        : firstAvatarMainLabel}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span className="avatarLabel">
                      {firstAvatarSecondLabel}
                    </span>
                  </Grid>
                </Grid>
              </a>
            </Grid>
            <Grid container item xs={12} sm={6} className="avatarMainContainer">
              <a
                className="avatarUrlLongCard"
                href={secondAvatarUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Grid item xs={3} sm={3} className="avatarContainer">
                  <AvatarBadge
                    avatarImg={secondAvatarImg}
                    color={secondAvatarStatusColor}
                    altImg={secondAvatarMainLabel}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={9}
                  sm={9}
                  className="avatarLabelsContainer"
                >
                  <Grid item xs={12} sm={12}>
                    <span className="avatarTitle">
                      {secondAvatarTitle.length > 25
                        ? secondAvatarTitle.substring(0, 25) + "..."
                        : secondAvatarTitle}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span className="avatarLabel">
                      {secondAvatarMainLabel.length > 25
                        ? secondAvatarMainLabel.substring(0, 25) + "..."
                        : secondAvatarMainLabel}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <span className="avatarLabel">
                      {secondAvatarSecondLabel}
                    </span>
                  </Grid>
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper className="longCardContainer" style={{ marginTop: "1%" }}>
            <Grid container item xs={12} sm={12} md={12}>
              <Grid
                container
                item
                xs={4}
                sm={4}
                md={4}
                className="titleContentContainer"
              >
                <Grid container item xs={11} sm={11} md={11}>
                  <Grid item xs={12} sm={12} md={12}>
                    <span className="titleCard">{titleCard}&nbsp;&nbsp;</span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className="titleButtonContainer"
                  >
                    <button
                      className="titleButton"
                      style={{
                        backgroundColor: titleButtonColor,
                      }}
                    >
                      {titleButtonLabel}
                    </button>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Divider
                    orientation="vertical"
                    style={{
                      height: "40px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={8} sm={8} md={8}>
                <Grid container item xs={4} sm={4} md={4}>
                  <a
                    className="avatarUrlLongCard"
                    href={firstAvatarUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Grid item xs={4} sm={4} md={3} className="avatarContainer">
                      <AvatarBadge
                        avatarImg={firstAvatarImg}
                        color={firstAvatarStatusColor}
                        altImg={firstAvatarMainLabel}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={8}
                      sm={8}
                      md={9}
                      className="avatarLabelsContainer"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <span className="avatarTitle">
                          {firstAvatarTitle.length > 21
                            ? firstAvatarTitle.substring(0, 21) + "..."
                            : firstAvatarTitle}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <span className="avatarLabel">
                          {firstAvatarMainLabel.length > 21
                            ? firstAvatarMainLabel.substring(0, 21) + "..."
                            : firstAvatarMainLabel}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <span className="avatarLabel">
                          {firstAvatarSecondLabel}
                        </span>
                      </Grid>
                    </Grid>
                  </a>
                </Grid>
                <Grid item xs={1} sm={1} md={1} />
                <Grid item xs={1} sm={1} md={1} />
                <Grid container item xs={4} sm={4} md={4}>
                  <a
                    className="avatarUrlLongCard"
                    href={secondAvatarUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Grid item xs={4} sm={4} md={3} className="avatarContainer">
                      <AvatarBadge
                        avatarImg={secondAvatarImg}
                        color={secondAvatarStatusColor}
                        altImg={secondAvatarMainLabel}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={8}
                      sm={8}
                      md={9}
                      className="avatarLabelsContainer"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <span className="avatarTitle">
                          {secondAvatarTitle.length > 21
                            ? secondAvatarTitle.substring(0, 21) + "..."
                            : secondAvatarTitle}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <span className="avatarLabel">
                          {secondAvatarMainLabel.length > 21
                            ? secondAvatarMainLabel.substring(0, 21) + "..."
                            : secondAvatarMainLabel}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <span className="avatarLabel">
                          {secondAvatarSecondLabel}
                        </span>
                      </Grid>
                    </Grid>
                  </a>
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  className="dateLabelContainer"
                >
                  <Grid item xs={5} sm={5} md={5}>
                    <Divider
                      orientation="vertical"
                      style={{
                        height: "40px",
                      }}
                    />
                  </Grid>
                  <Grid container item xs={6} sm={6} md={6}>
                    <Grid item xs={12} sm={12} md={12}>
                      <span className="dateLabel">{firstDateLabel}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <span className="dateLabel">{secondDateLabel}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}
