import isEmpty from "lodash/isEmpty";

import {
  MEETINGS_INFO_LOADING,
  MEETINGS_STATUS_LOADING,
  MEETINGS_ERROR,
  GET_MEETINGS_LIVE,
  MEETINGS_INFO,
  MEETING_STATUS,
  GET_MEETING_FETCHED,
  CLEAN_MEETINGS_DATA,
} from "../constants/actionTypesRedux/actionTypes";

export const meetingsInitialState = {
  loadingMeetingsInfo: false,
  loadingMeetingsStatus: false,
  success: false,
  error: null,
  meetingsInfo: {},
  meetingsData: {},
  meetingToFetch: {},
};

export default function meetingsReducer(state = meetingsInitialState, action) {
  switch (action.type) {
    case MEETINGS_INFO_LOADING:
      return {
        ...state,
        loadingMeetingsInfo: true,
        success: false,
      };

    case MEETINGS_STATUS_LOADING:
      return {
        ...state,
        loadingMeetingsStatus: true,
        success: false,
      };

    case MEETINGS_ERROR:
      return {
        ...state,
        loadingMeetingsInfo: false,
        loadingMeetingsStatus: false,
        success: false,
        error: action.error,
      };

    case MEETINGS_INFO:
      return {
        ...state,
        loadingMeetingsInfo: false,
        loadingMeetingsStatus: false,
        success: true,
        meetingsInfo: action.payload,
      };

    case CLEAN_MEETINGS_DATA:
      return {
        ...state,
        meetingsData: {},
      };

    case GET_MEETINGS_LIVE:
      return {
        ...state,
        loadingMeetingsInfo: false,
        loadingMeetingsStatus: false,
        success: true,
        meetingsData: action.payload,
      };

    case GET_MEETING_FETCHED:
      return {
        ...state,
        meetingsData: {
          ...state.meetingsData,
          businessConferenceMeetings: {
            ...state.meetingsData.businessConferenceMeetings,
            [action.meeting.id]: action.meeting,
          },
          businessConferenceParticipants: {
            ...state.meetingsData.businessConferenceParticipants,
            [action.host.id]: action.host,
            [action.participant.id]: action.participant,
          },
        },
      };

    case MEETING_STATUS:
      if (
        !isEmpty(state.meetingsData) &&
        state.meetingsData.businessConferenceMeetings[action.payload]
      ) {
        let hostIsWaiting =
          action.status === "waiting_for_participant"
            ? state.meetingsData.businessConferenceParticipants[
                state.meetingsData.businessConferenceMeetings[action.payload]
                  .relationships.host.data.id
              ].attributes.attendeeId === action.attendee
              ? true
              : false
            : false;

        let participantIsWaiting =
          action.status === "waiting_for_participant"
            ? state.meetingsData.businessConferenceParticipants[
                state.meetingsData.businessConferenceMeetings[action.payload]
                  .relationships.participant.data.id
              ].attributes.attendeeId === action.attendee
              ? true
              : false
            : false;

        return {
          ...state,
          meetingsData: {
            ...state.meetingsData,
            businessConferenceMeetings: {
              ...state.meetingsData.businessConferenceMeetings,
              [action.payload]: {
                ...state.meetingsData.businessConferenceMeetings[
                  action.payload
                ],
                status: action.status,
              },
            },
            businessConferenceParticipants: {
              ...state.meetingsData.businessConferenceParticipants,
              [state.meetingsData.businessConferenceMeetings[action.payload]
                .relationships.host.data.id]: {
                ...state.meetingsData.businessConferenceParticipants[
                  state.meetingsData.businessConferenceMeetings[action.payload]
                    .relationships.host.data.id
                ],
                waiting: hostIsWaiting,
              },
              [state.meetingsData.businessConferenceMeetings[action.payload]
                .relationships.participant.data.id]: {
                ...state.meetingsData.businessConferenceParticipants[
                  state.meetingsData.businessConferenceMeetings[action.payload]
                    .relationships.participant.data.id
                ],
                waiting: participantIsWaiting,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          loadingMeetingsInfo: false,
          loadingMeetingsStatus: false,
          success: true,
          meetingToFetch: action.payload,
        };
      }

    default:
      return state;
  }
}
