import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import "./CircularChartCard.css";
import { history } from "../../App";
import CircularChart from "./CircularChart";
export default function CircularChartCard(props) {
  const {
    cardTitle,
    firstDotLabel,
    secondDotLabel,
    thirdDotLabel,
    firstRightLabel,
    secondRightLabel,
    rightBottomLabel,
    eventuri,
    cardTitleColor,
    dotLabelColor,
    firstDotIconColor,
    secondDotIconColor,
    thirdDotIconColor,
    rightTitleColor,
    rightLabelColor,
    rightBottomLabelColor,
    arrowIconColor,
    chartTitleColor,
    chartLabelColor,
    firstColorChart,
    secondColorChart,
    thirdColorChart,
  } = props;

  const networkingData = useSelector((state) => state.event.networkingData);
  const businessConferencesData = useSelector(
    (state) => state.event.businessConferencesData
  );
  
  const meetingsData = () => {
    let numberOfMeetings = [
      { type: "Scheduled", value: 0 },
      { type: "Pending", value: 0 },
      { type: "Rejected", value: 0 },
    ];
    if (businessConferencesData.data) {
      businessConferencesData.data.map((businessConference) => {
        return (numberOfMeetings = [
          {
            type: "Scheduled",
            value:
              numberOfMeetings[0].value +
              businessConference.attributes.total_accepted_meetings,
          },
          {
            type: "Pending",
            value:
              numberOfMeetings[1].value +
              businessConference.attributes.total_requested_meetings,
          },
          {
            type: "Rejected",
            value:
              numberOfMeetings[2].value +
              businessConference.attributes.total_rejected_meetings,
          },
        ]);
      });
    }
    return numberOfMeetings;
  };

  const userData = useMemo(meetingsData, [businessConferencesData]);

  const totalMeetings = () => {
    let totalMeetings = 0;
    if (businessConferencesData.data) {
      businessConferencesData.data.map((businessConference) => {
        return (totalMeetings =
          totalMeetings +
          businessConference.attributes.total_accepted_meetings +
          businessConference.attributes.total_requested_meetings +
          businessConference.attributes.total_rejected_meetings);
      });
    }
    return `${totalMeetings} meetings`;
  };

  const chartLabel = useMemo(totalMeetings, [businessConferencesData]);

  const meetingsLiveHandleClick = () => {
    history.push(`/events/${eventuri}/meetingsLive`);
  };

  return (
    <Paper>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        className="circularChartCardContainer"
      >
        <Grid container item xs={11} sm={11} md={11}>
          <h1
            className="circularChartCardTitle"
            style={{ color: cardTitleColor }}
          >
            {cardTitle}
          </h1>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={2}
          md={2}
          className="chartLabelsContainer"
        >
          <Grid container item xs={4} sm={12} md={12}>
            <FiberManualRecordIcon
              className="chartDotIcon"
              style={{
                fontSize: "small",
                color: firstDotIconColor,
              }}
            />
            <span className="dotLabel" style={{ color: dotLabelColor }}>
              {firstDotLabel}
            </span>
          </Grid>
          <Grid
            container
            item
            xs={4}
            sm={12}
            md={12}
            className="chartDotIconContainer"
          >
            <FiberManualRecordIcon
              className="chartDotIcon"
              style={{
                fontSize: "small",
                color: secondDotIconColor,
              }}
            />
            <span className="dotLabel" style={{ color: dotLabelColor }}>
              {secondDotLabel}
            </span>
          </Grid>
          <Grid
            container
            item
            xs={4}
            sm={12}
            md={12}
            className="chartDotIconContainer"
          >
            <FiberManualRecordIcon
              className="chartDotIcon"
              style={{
                fontSize: "small",
                color: thirdDotIconColor,
              }}
            />
            <span className="dotLabel" style={{ color: dotLabelColor }}>
              {thirdDotLabel}
            </span>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={5} md={5} className="chartContainer">
          <CircularChart
            chartLabel={chartLabel}
            userData={userData}
            firstColorChart={firstColorChart}
            secondColorChart={secondColorChart}
            thirdColorChart={thirdColorChart}
            chartTitleColor={chartTitleColor}
            chartLabelColor={chartLabelColor}
          />
        </Grid>
        <Grid
          container
          item
          xs={false}
          sm={1}
          md={1}
          className="dividerContainer"
        >
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid container item xs={12} sm={4} md={4} className="statsContainer">
          <Grid container item xs={12} sm={8} md={8}>
            <Grid container item xs={6} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12}>
                <p className="statsTitle" style={{ color: rightTitleColor }}>
                  {networkingData.meetingsLive}
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <p className="statsLabel" style={{ color: rightLabelColor }}>
                  {firstRightLabel}
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={6} sm={12} md={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="statsTitleContainer"
              >
                <p className="statsTitle" style={{ color: rightTitleColor }}>
                  {networkingData.AttendeesInMeetings}
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <p className="statsLabel" style={{ color: rightLabelColor }}>
                  {secondRightLabel}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={4} sm={4} md={false} />
          <Grid
            container
            item
            xs={8}
            sm={12}
            md={12}
            className="footerLabelContainer"
          >
            <Grid item xs={11} sm={11} md={11}>
              <span
                className="footerLabel"
                style={{ color: rightBottomLabelColor }}
                onClick={() => meetingsLiveHandleClick()}
              >
                {rightBottomLabel}
              </span>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <IconButton
                onClick={() => meetingsLiveHandleClick()}
                style={{
                  padding: "0%",
                }}
                className="footerIconContainer"
              >
                <ArrowForwardIosIcon
                  style={{
                    fontSize: "small",
                    color: arrowIconColor,
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
