import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { format, formatDistanceStrict, compareAsc } from "date-fns";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import "./LiveActivities.css";
import LinearProgressBar from "../progressBar/LinearProgressBar";
import { LightTooltip } from "../tooltip/Tooltip";
import ChannelConection from "../ChannelConnection/ChannelConnection";
import { getListOfActivities } from "../../actions/eventCommander";
import StudioStartButton from './StudioStartButton';

export function Activities(props) {
  const {
    activities,
    attendeesLabel,
    activityMessages,
    timeLabelColor,
    activityNameColor,
    speakerLabelColor,
    attendeesTitleColor,
    attendeesLabelColor,
    // arrowIconColor,
  } = props;

  const [date, setDate] = useState(new Date()); // eslint-disable-next-line no-unused-vars
  const [broadcasts, setBroadcasts] = useState({});

  useEffect(() => {
    setInterval(() => {
      setDate(new Date());
    }, 1000);
  }, []);

  const formatDate = (date) => {
    return format(new Date(date), "hh:mm a");
  };

  const actualProgressOfActivity = (startDate, endDate, unit) => {
    let formatedStartDate = new Date(startDate);
    let formatedEndDate = new Date(endDate);
    let actualDate = date;

    if (compareAsc(actualDate, formatedEndDate) === 1) {
      return unit === "percentage" ? 100 : activityMessages.activityEnded;
    } else if (compareAsc(actualDate, formatedStartDate) === -1) {
      return unit === "percentage" ? 0 : activityMessages.activityNotStarted;
    } else {
      let activityDurationInMinutes = formatDistanceStrict(
        formatedStartDate,
        formatedEndDate,
        { unit: "minute" }
      );
      let currentMinutesInActivity = formatDistanceStrict(
        formatedStartDate,
        actualDate
      );
      let actualProgressPercentage =
        (currentMinutesInActivity.split(' ')[0] * 100) / activityDurationInMinutes.split(' ')[0];
      return unit === "percentage"
        ? actualProgressPercentage
        : `${currentMinutesInActivity.split(' ')[0]} ${activityMessages.minsLive}`;
    }
  };

  const attendeesInActivity = (activityguid) => {
    let attendeesOnlineInActivity = activities.attendees[activityguid];
    return attendeesOnlineInActivity;
  };

  const speakerAttributes = (activityId, attribute) => {
    if (activities.speakers !== undefined) {
      let speakerProps = activities.speakers.find((speaker) => {
        return speaker.relationships.workshops.data.find((workshop) => {
          return workshop.id === activityId ? speaker : null;
        });
      });
      if (speakerProps !== undefined) {
        if (attribute === "name") {
          return speakerProps.attributes?.full_name;
        } else {
          return speakerProps.attributes?.position;
        }
      } else {
        return "No Speaker";
      }
    } else {
      return "No Speaker";
    }
  };

  return activities.data.map((activity, activityIndex) => {
    const activityTopic = `workshop_commander:${activity.attributes.guid}`;

    return (
      <Grid
        key={activityIndex}
        container
        item
        xs={12}
        sm={12}
        md={12}
        className="activityContainer"
      >
        <ChannelConection topic={activityTopic} setBroadcasts={setBroadcasts} />
        <Grid container item xs={11} sm={11} md={11}>
          <Grid
            className="timeLabelContainer"
            container
            item
            xs={3}
            sm={2}
            md={2}
          >
            <p className="timeLabel" style={{ color: timeLabelColor }}>
              {formatDate(activity.attributes.start_date)}
            </p>
            <p className="timeLabel" style={{ color: timeLabelColor }}>
              {formatDate(activity.attributes.end_date)}
            </p>
          </Grid>
          <Grid container item xs={8} sm={5} md={5}>
            <Grid container item xs={12} sm={12} md={12}>
              <h1 className="activityName" style={{ color: activityNameColor }}>
                {activity.attributes.name}
              </h1>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              className="speakerContainer"
            >
              <span
                className="speakerLabel"
                style={{ color: speakerLabelColor }}
              >
                {speakerAttributes(activity.id, "name")}
              </span>
              {speakerAttributes(activity.id, "name") ===
              "No Speaker" ? null : (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      height: "12px",
                      marginTop: "1%",
                      marginLeft: "2%",
                      marginRight: "2%",
                      backgroundColor: speakerLabelColor,
                    }}
                  />
                  <span
                    className="speakerLabel"
                    style={{ color: speakerLabelColor }}
                  >
                    {speakerAttributes(activity.id, "role")}
                  </span>
                </>
              )}
            </Grid>
            <LightTooltip
              title={actualProgressOfActivity(
                activity.attributes.start_date,
                activity.attributes.end_date,
                "minutes"
              )}
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                className="progressBarContainer"
              >
                <LinearProgressBar
                  progressPercentage={actualProgressOfActivity(
                    activity.attributes.start_date,
                    activity.attributes.end_date,
                    "percentage"
                  )}
                />
              </Grid>
            </LightTooltip>
          </Grid>
          <Grid container item xs={3} sm={3} md={3}>
            {activity.attributes.streaming_type === "eventtia_studio" && (
              <StudioStartButton
                broadcast={broadcasts[activityTopic]}
              />
            )}
          </Grid>
          <Grid container item xs={2} sm={2} md={2}>
            <Grid item xs={12} sm={12} md={12}>
              <p
                className="attendeesTitle"
                style={{ color: attendeesTitleColor }}
              >
                {attendeesInActivity(activity.attributes.guid)}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p
                className="attendeesLabel"
                style={{ color: attendeesLabelColor }}
              >
                {attendeesLabel}
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "90%", marginLeft: "4%", marginTop: "3%" }} />
      </Grid>
    );
  });
}

export function LiveActivities(props) {
  const {
    cardTitle,
    attendeesLabel,
    activities,
    activityMessages,
    noActivitiesImg,
    noActivitiesMessage,
    eventUri,
    jwtToken,
    cardTitleColor,
    dotIconColor,
    timeLabelColor,
    progressBarColor,
    progressBarBackgroundColor,
    activityNameColor,
    speakerLabelColor,
    attendeesTitleColor,
    attendeesLabelColor,
    arrowIconColor,
    paginatorActiveArrowColor,
    paginatorInactiveArrowColor,
    noActivitiesMessageColor,
  } = props;

  const dispatch = useDispatch();

  const fetchPaginator = (pageURL) => {
    dispatch(getListOfActivities(eventUri, jwtToken, pageURL));
  };

  return (
    <Paper>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        className="liveActivitiesContainer"
      >
        <Grid container item xs={11} sm={11} md={11}>
          <FiberManualRecordIcon
            className="dotIcon"
            style={{
              color: dotIconColor,
              fontSize: "small",
            }}
          />
          <h1 className="liveActivitiesTitle" style={{ color: cardTitleColor }}>
            {cardTitle}
          </h1>
        </Grid>
        {activities.data.length > 0 ? (
          <>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              className="activitiesContainer"
            >
              {
                <Activities
                  activities={activities}
                  attendeesLabel={attendeesLabel}
                  activityMessages={activityMessages}
                  timeLabelColor={timeLabelColor}
                  progressBarColor={progressBarColor}
                  progressBarBackgroundColor={progressBarBackgroundColor}
                  activityNameColor={activityNameColor}
                  speakerLabelColor={speakerLabelColor}
                  attendeesTitleColor={attendeesTitleColor}
                  attendeesLabelColor={attendeesLabelColor}
                  arrowIconColor={arrowIconColor}
                />
              }
            </Grid>
            <Grid container item xs={12} sm={12} md={12}>
              <Grid item xs={5} sm={5} md={5} />
              <Grid container item xs={2} sm={2} md={2}>
                <Grid item xs={4} sm={4} md={4}>
                  <IconButton
                    onClick={
                      activities.links.prev !== null
                        ? () => fetchPaginator(activities.links.prev)
                        : null
                    }
                  >
                    <ArrowBackIosIcon
                      style={{
                        fontSize: 15,
                        color:
                          activities.links.prev !== null
                            ? paginatorActiveArrowColor
                            : paginatorInactiveArrowColor,
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <span className="activityPageNumber">
                    {
                      activities?.links?.self
                        ?.split("number%5D=")[1]
                        .split("")[0]
                    }
                  </span>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <IconButton
                    onClick={
                      activities.links.next !== null
                        ? () => fetchPaginator(activities.links.next)
                        : null
                    }
                  >
                    <ArrowForwardIosIcon
                      style={{
                        fontSize: 15,
                        color:
                          activities.links.next !== null
                            ? paginatorActiveArrowColor
                            : paginatorInactiveArrowColor,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container item xs={12} sm={12} md={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="noActivitiesImgContainer"
            >
              <img
                src={noActivitiesImg}
                alt="noLiveActivities"
                className="noActivitiesImg"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="noActivitiesMessageContainer"
            >
              <span
                style={{ color: noActivitiesMessageColor }}
                className="noActivitiesMessage"
              >
                {noActivitiesMessage}
              </span>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
