export default {
  en: {
    liveEventAnalyticsTitle: "Event Commander (Real time data)",
    eventWithNoName: "Ipsum Event",
    localTime: "Local time is ",
    registeredAttendees: "Registered attendees",
    attendeesOnline: "Attendees online",
    checkInAttendees: "Unique connected attendees",
    attendance: "Attendance",
    activeAttendees: "Active attendees",
    liveAnnouncements: "Live Announcements",
    liveAnnouncementWarning:
      "This announcement will be visible for all the attendees in the event.",
    send: "Send",
    publishDialogTitle: "Publish Announcement",
    publishDialogSubtitle: "Are you sure you want to send this message?",
    publishDialogButtonSubmit: "Send",
    publishDialogButtonCancel: "Cancel",
    successAnnouncement: "Announcement published with success",
    errorAnnouncement: "Announcement could not be published, please try again",
    liveActivities: "Live activities",
    attendees: "Attendees",
    networking: "Networking",
    meetingScheduled: "Scheduled",
    meetingPending: "Pending",
    meetingRejected: "Rejected",
    meetingsLiveNow: "Meetings live now",
    attendeesInMeetings: "Attendees in meetings",
    viewMeetingsLiveNow: "View live meetings",
    activityMessages: {
      minsLive: "mins live",
      activityEnded: "this activity is already finished",
      activityNotStarted: "this activity has not started",
    },
    noActivities: "There are no live activities at the moment.",
    poweredBy: "Powered by ",
    eventtia: "eventtia",
  },
};
