import React from "react";
import Grid from "@material-ui/core/Grid";

import "./ButtonMenu.css";

export default function ButtonMenu(props) {
  const {
    firstButtonLabel,
    secondButtonLabel,
    buttonSelected,
    buttonHandleClick,
    dotLabel,
  } = props;

  return (
    <>
      <Grid container item xs={12} sm={12} md={12} className="buttonMenuMainContainer">
        <button
          onClick={() => buttonHandleClick("firstButton")}
          className={`menuButton ${
            buttonSelected === "firstButton"
              ? "selectedButton"
              : "unselectedButton"
          }`}
        >
          {firstButtonLabel}&nbsp;&nbsp;&nbsp;
          {buttonSelected === "firstButton" ? (
            <button className="dotLabelButtonMenu">{dotLabel}</button>
          ) : null}
        </button>

        <button
          onClick={() => buttonHandleClick("secondButton")}
          className={`menuButton ${
            buttonSelected === "secondButton"
              ? "selectedButton"
              : "unselectedButton"
          }`}
        >
          {secondButtonLabel}&nbsp;&nbsp;&nbsp;
          {buttonSelected === "secondButton" ? (
            <button className="dotLabelButtonMenu">{dotLabel}</button>
          ) : null}
        </button>
      </Grid>
    </>
  );
}
