
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Login from './views/login/Login';

export function UnauthenticatedRoutes(props){
    return(
        <Router history={props.history}>
            <div>
            <Switch>
                <Route path="/:locale/eventtia-sso/:token" exact component={Login} />
            </Switch>
            </div>
        </Router>
    )
}