import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from "../constants/actionTypesRedux/actionTypes";

export const loginInitialState = {
  loading: false,
  jwtToken: false,
  success: false,
  error: null,
};

export default function loginReducer(state = loginInitialState, action) {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        jwtToken: action.payload,
        success: true,
      };
    default:
      return state;
  }
}
