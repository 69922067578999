import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { format } from "date-fns";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./EventCommander.css";
import noActivitiesImg from "../../assets/img/noActivities.png";
import messages from "../../constants/messages/eventCommander";
import {
  mainPurple,
  mainGray,
  secondaryGray,
  mainTurquoise,
  mainAquamarine,
  secondaryWhite,
  secondaryPurple,
  mainWhite,
  mainRed,
  thirdWhite,
  secondaryTurquoise,
  mainYellow,
  thirdPurple,
  thirdGray,
  mainBlack,
} from "../../constants/colors/eventtiaColors";
import { getEvent } from "../../actions/eventCommander";
import LocalTime from "../../components/localTime/LocalTime";
import ChannelConection from "../../components/ChannelConnection/ChannelConnection";
import SmallCard from "../../components/cards/SmallCard";
import AttendeesCard from "../../components/attendeesCard/AttendeesCard";
import AreaChart from "../../components/areaChart/AreaChart";
import CommentCard from "../../components/cards/CommentCard";
import Snackbar from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import { LiveActivities } from "../../components/liveActivities/LiveActivities";
import CircularChartCard from "../../components/circularChart/CircularChartCard";

export default function EventCommander(props) {
  const dispatch = useDispatch();
  const jwtToken = sessionStorage.getItem("currentUserToken");
  const { event_uri } = props.match.params;

  const eventData = useSelector((state) => state.event.eventData);
  const eventAttendeesOnline = useSelector(
    (state) => state.event.eventAttendeesOnline
  );
  const activitiesData = useSelector((state) => state.event.activitiesData);
  const checkedInAttendees = useSelector(
    (state) => state.event.checkedInAttendees
  );
  const businessConferencesData = useSelector(
    (state) => state.event.businessConferencesData
  );

  const [broadcasts, setBroadcasts] = useState({});
  const [activeAttendeesData, setActiveAttendeesData] = useState({
    data: { [format(new Date(), "hh:mm a")]: eventAttendeesOnline },
    attendees: eventAttendeesOnline,
  });
  const [announcementToPublish, setAnnouncementToPublish] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openMessage, setOpenMessage] = useState({
    openMessage: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    dispatch(getEvent(event_uri, jwtToken)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveAttendeesData((activeAttendeesData) => {
      return {
        ...activeAttendeesData,
        attendees: eventAttendeesOnline,
      };
    });
  }, [eventAttendeesOnline]);

  useEffect(() => {
    setInterval(() => {
      let actualTime = format(new Date(), "hh:mm a");
      setActiveAttendeesData((activeAttendeesData) => {
        if (isEmpty(activeAttendeesData.data[actualTime])) {
          return {
            ...activeAttendeesData,
            data: {
              ...activeAttendeesData.data,
              [actualTime]: activeAttendeesData.attendees,
            },
          };
        } else {
          return activeAttendeesData;
        }
      });
    }, 600000);
  }, []);

  const eventTopic = `event_commander:${event_uri}`;
  const live_announcements = `live_announcements`;

  const activeAttendees = () => {
    let activeAttendeesArray = Object.keys(activeAttendeesData.data).map(
      (hour) => {
        return { hour, attendees: activeAttendeesData.data[hour] };
      }
    );
    return activeAttendeesArray;
  };

  const liveAnnouncementHandleSubmit = () => {
    Object.values(broadcasts)[0](live_announcements, {
      body: announcementToPublish.announcement,
      type: announcementToPublish.type,
    })
      .receive("ok", () => {
        setOpenDialog(false);
        setOpenMessage({
          openMessage: true,
          message: messages.en.successAnnouncement,
          severity: "success",
        });
      })
      .receive("error", () => {
        setOpenDialog(false);
        setOpenMessage({
          openMessage: true,
          message: messages.en.errorAnnouncement,
          severity: "error",
        });
      });
  };

  const liveAnnouncementOpenDialog = (announcement, type) => {
    setAnnouncementToPublish({ announcement, type });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseMessage = () => {
    setOpenMessage({ openMessage: false, message: "", severity: "" });
  };

  return (
    <>
      {!isEmpty(eventData) &&
      !isEmpty(activitiesData) &&
      !isEmpty(checkedInAttendees) &&
      !isEmpty(businessConferencesData) ? (
        <Container>
          <ChannelConection
            topic={eventTopic}
            businessConferenceId={businessConferencesData?.data[0]?.id}
            setBroadcasts={setBroadcasts}
          />
          <Grid container item xs={12} sm={12} md={12}>
            <Snackbar
              open={openMessage.openMessage}
              autoHideDuration={3000}
              severity={openMessage.severity}
              message={openMessage.message}
              handleClose={handleCloseMessage}
            />
            <Dialog
              open={openDialog}
              handleClose={handleCloseDialog}
              submitButtonOnClick={liveAnnouncementHandleSubmit}
              title={messages.en.publishDialogTitle}
              subtitle={messages.en.publishDialogSubtitle}
              text={`"${announcementToPublish.announcement}"`}
              submitButtonlabel={messages.en.publishDialogButtonSubmit}
              cancelButtonLabel={messages.en.publishDialogButtonCancel}
              titleColor={mainBlack}
              closeIconColor={secondaryPurple}
              subtitleColor={mainGray}
              textColor={mainPurple}
              submitButtonColor={mainWhite}
              submitButtonBackgroundColor={mainTurquoise}
              cancelButtonColor={thirdPurple}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: "2%" }}
          >
            <Grid item xs={12} sm={5} md={5}>
              <h1 className="eventCommanderTitle" style={{ color: mainPurple }}>
                {messages.en.liveEventAnalyticsTitle}
              </h1>
              <span
                className="eventCommanderSubtitle"
                style={{ color: mainGray }}
              >
                {!isEmpty(eventData)
                  ? eventData.attributes.name
                  : messages.en.eventWithNoName}
              </span>
            </Grid>
            <Grid item xs={false} sm={3} md={4} />
            <Grid
              container
              item
              xs={12}
              sm={4}
              md={3}
              className="timeContainer"
            >
              <LocalTime iconColor={secondaryGray} labelColor={secondaryGray} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            style={{ marginTop: "3%", height: "100%", width: "100%" }}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              style={{ height: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                className="registeredAttendeesCardContainer"
              >
                <SmallCard
                  rightSide={false}
                  titleLeftSide={eventData.attributes.total_attendees}
                  labelLeftSide={messages.en.registeredAttendees}
                  colorLeftBar={mainPurple}
                  colorTitleLeftSide={mainPurple}
                  colorLabelLeftSide={secondaryGray}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                className="attendeesOnlineCardContainer"
              >
                <AttendeesCard />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                className="checkInAttendeesCardContainer"
              >
                <SmallCard
                  rightSide={false}
                  titleLeftSide={checkedInAttendees.meta.total_attendees}
                  labelLeftSide={messages.en.checkInAttendees}
                  colorLeftBar={mainYellow}
                  colorTitleLeftSide={mainYellow}
                  colorLabelLeftSide={secondaryGray}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={11} className="areaChart">
                <AreaChart
                  cardTitle={messages.en.activeAttendees}
                  data={activeAttendees()}
                  tooltipLabelX={"hour"}
                  tooltipLabelY={"attendees"}
                  cardTitleColor={secondaryGray}
                  lineColor={mainTurquoise}
                  firstGradientColor={secondaryWhite}
                  secondaryGradientColor={mainAquamarine}
                  thirdGradientColor={mainTurquoise}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={11} className="commentCard">
                <CommentCard
                  cardTitle={messages.en.liveAnnouncements}
                  cardLabel={messages.en.liveAnnouncementWarning}
                  commentCardButtonLabel={messages.en.send}
                  maxLength="300"
                  buttonOnClick={liveAnnouncementOpenDialog}
                  cardTitleColor={secondaryGray}
                  cardLabelColor={secondaryGray}
                  commentCardButtonLabelColor={mainWhite}
                  commentCardButtonBackgroundColor={mainTurquoise}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              style={{ height: "100%" }}
            >
              <Grid item xs={12} sm={12} md={12} className="liveActivities">
                <LiveActivities
                  cardTitle={messages.en.liveActivities}
                  activities={activitiesData}
                  attendeesLabel={messages.en.attendees}
                  activityMessages={messages.en.activityMessages}
                  noActivitiesImg={noActivitiesImg}
                  noActivitiesMessage={messages.en.noActivities}
                  jwtToken={jwtToken}
                  eventUri={eventData.attributes.event_uri}
                  cardTitleColor={secondaryGray}
                  dotIconColor={mainRed}
                  timeLabelColor={secondaryGray}
                  progressBarColor={mainTurquoise}
                  progressBarBackgroundColor={thirdWhite}
                  activityNameColor={secondaryGray}
                  speakerLabelColor={secondaryGray}
                  attendeesTitleColor={mainPurple}
                  attendeesLabelColor={secondaryGray}
                  arrowIconColor={secondaryPurple}
                  paginatorActiveArrowColor={secondaryGray}
                  paginatorInactiveArrowColor={thirdGray}
                  noActivitiesMessageColor={secondaryGray}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="circularChartCard">
                <CircularChartCard
                  cardTitle={messages.en.networking}
                  firstDotLabel={messages.en.meetingScheduled}
                  secondDotLabel={messages.en.meetingPending}
                  thirdDotLabel={messages.en.meetingRejected}
                  eventuri={event_uri}
                  firstRightLabel={messages.en.meetingsLiveNow}
                  secondRightLabel={messages.en.attendeesInMeetings}
                  rightBottomLabel={messages.en.viewMeetingsLiveNow}
                  cardTitleColor={secondaryGray}
                  dotLabelColor={secondaryGray}
                  firstDotIconColor={secondaryTurquoise}
                  secondDotIconColor={mainYellow}
                  thirdDotIconColor={mainRed}
                  rightTitleColor={mainPurple}
                  rightLabelColor={secondaryGray}
                  rightBottomLabelColor={thirdPurple}
                  arrowIconColor={thirdPurple}
                  chartTitleColor={secondaryGray}
                  chartLabelColor={secondaryGray}
                  firstColorChart={secondaryTurquoise}
                  secondColorChart={mainYellow}
                  thirdColorChart={mainRed}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="poweredByEventtiaFooter"
          >
            <span className="poweredByEventtiaFooterLabel">
              <span
                className="poweredByFooterLabel"
                style={{ color: secondaryGray }}
              >
                {messages.en.poweredBy}
              </span>
              <span
                className="eventtiaFooterLabel"
                style={{ color: secondaryGray }}
              >
                {messages.en.eventtia}
              </span>
            </span>
          </Grid>
        </Container>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className="eventCommanderLoaderContainer"
        >
          <CircularProgress
            size={100}
            style={{
              color: mainTurquoise,
            }}
          />
        </Grid>
      )}
    </>
  );
}
