import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { format, addHours } from "date-fns";
import Grid from "@material-ui/core/Grid";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./MeetingsLive.css";
import { history } from "../../App";
import noMeetingsLiveImg from "../../assets/img/noActivities.png";
import messages from "../../constants/messages/meetingsLive";
import { getEvent } from "../../actions/eventCommander";
import { getMeetingsLive } from "../../actions/meetingsCommander";
import {
  mainPurple,
  secondaryPurple,
  mainGray,
  secondaryGray,
  mainTurquoise,
  thirdTurquoise,
  secondaryYellow,
  mainRed,
} from "../../constants/colors/eventtiaColors";
import ButtonMenu from "../../components/buttonMenu/ButtonMenu";
import LongCard from "../../components/cards/LongCard";
import ChannelConection from "../../components/ChannelConnection/ChannelConnection";

export default function MeetingsLive(props) {
  const dispatch = useDispatch();
  const jwtToken = sessionStorage.getItem("currentUserToken");
  const { event_uri } = props.match.params;

  const eventData = useSelector((state) => state.event.eventData);
  const businessConferencesData = useSelector(
    (state) => state.event.businessConferencesData
  );
  const meetingsInfo = useSelector((state) => state.meetings.meetingsInfo);
  const meetingsData = useSelector((state) => state.meetings.meetingsData);
  const meetingToFetch = useSelector((state) => state.meetings.meetingToFetch);
  const meetingsLoading = useSelector(
    (state) => state.meetings.loadingMeetingsInfo
  );

  const [broadcasts, setBroadcasts] = useState({});
  const [date, setDate] = useState(format(new Date(), "hh:mm aaaa O"));
  const [buttonSelected, setButtonSelected] = useState("firstButton");

  const meetings_info = `meetings_info`;

  useEffect(() => {
    dispatch(getEvent(event_uri, jwtToken, true)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInterval(() => {
      setDate(format(new Date(), "hh:mm aaaa O"));
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isEmpty(meetingsInfo)) {
      dispatch(
        getMeetingsLive(
          buttonSelected === "firstButton"
            ? meetingsInfo.data
            : Object.keys(meetingsInfo.data),
          buttonSelected === "firstButton"
            ? "in_progress"
            : "waiting_for_participant",
          businessConferencesData.data[0]?.id,
          event_uri,
          jwtToken,
          buttonSelected === "secondButton"
            ? Object.values(meetingsInfo.data)
            : false,
          true
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingsInfo]);

  useEffect(() => {
    if (!isEmpty(meetingToFetch)) {
      dispatch(
        getMeetingsLive(
          meetingToFetch,
          buttonSelected === "firstButton"
            ? "in_progress"
            : "waiting_for_participant",
          businessConferencesData.data[0]?.id,
          event_uri,
          jwtToken
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingToFetch]);

  useEffect(() => {
    if (!isEmpty(broadcasts)) {
      Object.values(broadcasts)[0](meetings_info, {
        status:
          buttonSelected === "firstButton"
            ? "in_progress"
            : "waiting_for_participant",
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(broadcasts)) {
      Object.values(broadcasts)[0](meetings_info, {
        status:
          buttonSelected === "firstButton"
            ? "in_progress"
            : "waiting_for_participant",
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSelected]);

  const phoneResolution = useMediaQuery("(max-width: 480px)");

  const localTime = () => {
    let timeLabel = messages.en.localTime + date;
    return timeLabel;
  };

  const arrowBackHandleClick = () => {
    history.push(`/events/${event_uri}`);
  };

  const meetingsLiveTime = () => {
    let actualHour = format(new Date(), "h:mm a");
    let finishHour = format(addHours(new Date(), 1), "h:mm a");
    return `${actualHour} - ${finishHour}`;
  };

  const filterButtonsHandleClick = (filter) => {
    setButtonSelected(filter);
  };

  const totalMeetings = () => {
    if (meetingsLoading) {
      return 0;
    } else if (meetingsData.businessConferenceMeetings) {
      return Object.keys(meetingsData.businessConferenceMeetings).length;
    } else {
      return 0;
    }
  };

  // const filterMeetings = () => {
  //   if (meetingsData.businessConferenceMeetings) {
  //     let filteredMeetings = {};
  //     Object.values(meetingsData.businessConferenceMeetings).forEach(
  //       (meeting) => {
  //         if (
  //           meeting.status === "waiting_for_participant" &&
  //           buttonSelected === "secondButton"
  //         ) {
  //           filteredMeetings[meeting.id] = meeting;
  //         }
  //       }
  //     );
  //     meetingsLiveNowCards(filteredMeetings);
  //   }
  // };

  const meetingsLiveNowCards = () => {
    if (meetingsData.businessConferenceMeetings) {
      let filteredMeetings = {};
      Object.values(meetingsData.businessConferenceMeetings).forEach(
        (meeting) => {
          if (
            (meeting.status === "in_progress" &&
              buttonSelected === "firstButton") ||
            (meeting.status === "waiting_for_participant" &&
              buttonSelected === "secondButton")
          ) {
            filteredMeetings[meeting.id] = meeting;
          }
        }
      );
      let meetingsCards = Object.values(filteredMeetings).map((meeting) => {
        return (
          <>
            <LongCard
              titleCard={`${messages.en.meetingScheduledByLabel} ${
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.host.data.id
                ].attributes.profileContactName.length > 20
                  ? meetingsData.businessConferenceParticipants[
                      meeting.relationships.host.data.id
                    ].attributes.profileContactName.substring(0, 20) + "..."
                  : meetingsData.businessConferenceParticipants[
                      meeting.relationships.host.data.id
                    ].attributes.profileContactName
              }`}
              titleButtonLabel={
                buttonSelected === "firstButton"
                  ? "In progress"
                  : "Waiting for attendee"
              }
              titleButtonColor={
                buttonSelected === "firstButton"
                  ? thirdTurquoise
                  : secondaryYellow
              }
              firstAvatarUrl={`https://connect.eventtia.com/en/events/${
                eventData.id
              }/attendees/${
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.host.data.id
                ].attributes.attendeeId
              }/edit`}
              firstAvatarTitle={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.host.data.id
                ].attributes.profileDisplayName
              }
              firstAvatarMainLabel={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.host.data.id
                ].attributes.profileContactName
              }
              firstAvatarSecondLabel={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.host.data.id
                ].attributes.participantTypeName
              }
              firstAvatarImg={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.host.data.id
                ].attributes.logo.small
              }
              firstAvatarStatusColor={
                buttonSelected === "firstButton"
                  ? thirdTurquoise
                  : meetingsData.businessConferenceParticipants[
                      meeting.relationships.host.data.id
                    ].waiting
                  ? thirdTurquoise
                  : mainRed
              }
              secondAvatarUrl={`https://connect.eventtia.com/en/events/${
                eventData.id
              }/attendees/${
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.participant.data.id
                ].attributes.attendeeId
              }/edit`}
              secondAvatarTitle={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.participant.data.id
                ].attributes.profileDisplayName
              }
              secondAvatarMainLabel={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.participant.data.id
                ].attributes.profileContactName
              }
              secondAvatarSecondLabel={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.participant.data.id
                ].attributes.participantTypeName
              }
              secondAvatarImg={
                meetingsData.businessConferenceParticipants[
                  meeting.relationships.participant.data.id
                ].attributes.logo.small
              }
              secondAvatarStatusColor={
                buttonSelected === "firstButton"
                  ? thirdTurquoise
                  : meetingsData.businessConferenceParticipants[
                      meeting.relationships.participant.data.id
                    ].waiting
                  ? thirdTurquoise
                  : mainRed
              }
              firstDateLabel={format(
                new Date(
                  meetingsData.businessConferenceMeetings[
                    meeting.id
                  ].attributes.startDate
                ),
                "h:mm a"
              )}
              secondDateLabel={format(
                new Date(
                  meetingsData.businessConferenceMeetings[
                    meeting.id
                  ].attributes.endDate
                ),
                "h:mm a"
              )}
              dotsIconColor={secondaryPurple}
            />
          </>
        );
      });
      return meetingsCards;
    }
  };

  return (
    <>
      {!isEmpty(businessConferencesData) || !isEmpty(meetingsData) ? (
        <>
          <ChannelConection
            topic={`meetings_commander:${businessConferencesData?.data[0]?.id}`}
            status={
              buttonSelected === "firstButton"
                ? "in_progress"
                : "waiting_for_participant"
            }
            setBroadcasts={setBroadcasts}
          />
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: "1%" }}
          >
            <Grid
              container
              item
              xs={1}
              sm={1}
              md={1}
              className="arrowBackContainer"
            >
              <Grid item xs={5} sm={4} md={8} />
              <Grid item xs={7} sm={8} md={4}>
                <IconButton
                  onClick={() => arrowBackHandleClick()}
                  style={{
                    padding: "0%",
                  }}
                >
                  <ArrowBackIosIcon
                    style={{
                      fontSize: "30",
                      color: mainPurple,
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={11} sm={7} md={8}>
              <h1 className="meetingsLiveTitle" style={{ color: mainPurple }}>
                {messages.en.liveEventAnalyticsTitle}
              </h1>
              <span
                className="meetingsLiveSubtitle"
                style={{ color: mainGray }}
              >
                {!isEmpty(eventData)
                  ? eventData.attributes.name
                  : messages.en.eventWithNoName}
              </span>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={4}
              md={3}
              className="timeContainer"
            >
              <AccessTimeIcon
                fontSize="small"
                className="timeIcon"
                style={{ color: secondaryGray }}
              />
              <span
                className="meetingsLiveTime"
                style={{ color: secondaryGray }}
              >
                {localTime()}
              </span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={false}
            md={false}
            className="meetingsDividerContainer"
          >
            {phoneResolution ? <Divider /> : null}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            className="meetingsLiveContentContainer"
          >
            <Grid container item xs={1} sm={1} md={1} />
            <Grid container item xs={10} sm={10} md={10}>
              <Grid container item xs={12} sm={12} md={12}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={5}
                  md={4}
                  style={{ marginTop: "4%" }}
                  className="meetingsLiveNowTimeLabelContainer"
                >
                  <span
                    className="meetingsLiveNowLabel"
                    style={{ color: secondaryGray }}
                  >
                    {messages.en.meetingsLiveNowLabel}&nbsp;&nbsp;&nbsp;
                  </span>
                  <span
                    className="meetingsLiveNowTime"
                    style={{ color: mainPurple }}
                  >
                    {meetingsLiveTime()}
                  </span>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "4%" }}
                >
                  <ButtonMenu
                    firstButtonLabel={
                      messages.en.meetingsFilterMenuLabels.inProgressMeetings
                    }
                    secondButtonLabel={
                      messages.en.meetingsFilterMenuLabels.waitingMeetings
                    }
                    buttonSelected={buttonSelected}
                    buttonHandleClick={filterButtonsHandleClick}
                    dotLabel={totalMeetings()}
                  />
                </Grid>
                {meetingsLoading ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className="meetingsCommanderLoaderContainer"
                  >
                    <CircularProgress
                      size={100}
                      style={{
                        color: mainTurquoise,
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: "2%" }}
                  >
                    {!isEmpty(meetingsData) ? (
                      meetingsLiveNowCards()
                    ) : (
                      <Paper className="noMeetingsContentContainer">
                        <Grid container item xs={12} sm={12} md={12}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="noMeetingsLiveImgContainer"
                          >
                            <img
                              src={noMeetingsLiveImg}
                              alt="noMeetingsLiveImg"
                              className="noMeetingsLiveImg"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="noMeetingsLiveMessageContainer"
                          >
                            <span
                              style={{ color: secondaryGray }}
                              className="noMeetingsLiveMessage"
                            >
                              {messages.en.noMeetingsLiveMessage}
                            </span>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={1} sm={1} md={1} />
          </Grid>
        </>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className="meetingsCommanderLoaderContainer"
        >
          <CircularProgress
            size={100}
            style={{
              color: mainTurquoise,
            }}
          />
        </Grid>
      )}
    </>
  );
}
