import React, { useEffect, useMemo } from "react";
import { Socket } from "phoenix";
import { SOCKET_URL } from "../../constants/urls/urls";
import SocketContext from "../../contexts/SocketContext/SocketContext";

const SocketProvider = ({children}) => {
  const jwtToken = sessionStorage.getItem("currentUserToken");

  const socket = useMemo(
    () => new Socket(SOCKET_URL, { params: { token: jwtToken } }),
    [jwtToken]
  );

  useEffect(() => {
    if (socket) socket.connect();
    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  return (
      <SocketContext.Provider value={socket}>
          {children}
      </SocketContext.Provider>
  )
};

export default SocketProvider;