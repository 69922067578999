import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import "./Login.css";
import eventtiaImg from "../../assets/img/eventtia.jpg";
import { authUser } from "../../actions/login";

export default function Login(props) {
  const dispatch = useDispatch();
  const { token } = props.match.params; // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(authUser(token)), [dispatch]);
  return (
    <>
      <Container>
        <Grid container item md={12} className="loginViewContainer">
          <Grid container item md={12} className="loginContainer">
            <Grid item md={12} className="imgContainer">
              <img className="eventtiaImg" src={eventtiaImg} alt="eventtia" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
