import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import "./Dialog.css";

export default function CustomizedDialogs(props) {
  const {
    open,
    handleClose,
    submitButtonOnClick,
    title,
    subtitle,
    text,
    submitButtonlabel,
    cancelButtonLabel,
    titleColor,
    closeIconColor,
    subtitleColor,
    textColor,
    submitButtonColor,
    submitButtonBackgroundColor,
    cancelButtonColor,
  } = props;

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          className="dialogHeaderContainer"
        >
          <Container>
            <Grid container item xs={12} sm={12} md={12}>
              <Grid item xs={9} sm={9} md={9} className="dialogTitleContainer">
                <span className="dialogTitle" style={{ color: titleColor }}>
                  {title}
                </span>
              </Grid>
              <Grid item xs={2} sm={2} md={2} />
              <Grid item xs={1} sm={1} md={1} className="dialogIconContainer">
                <IconButton onClick={handleClose} style={{ padding: 0 }}>
                  <CloseIcon style={{color: closeIconColor, fontSize: 25}} />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Container>
          <Grid container item xs={12} sm={12} md={12}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              className="dialogContentContainer"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="dialogSubtitleContainer"
              >
                <span
                  className="dialogSubtitle"
                  style={{ color: subtitleColor }}
                >
                  {subtitle}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="dialogTextContainer"
              >
                <span className="dialogText" style={{ color: textColor }}>
                  {text}
                </span>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              className="dialogButtonsContainer"
            >
              <Grid item xs={12} sm={12} md={12} style={{ padding: "5%" }}>
                <Divider variant="middle" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="dialogSubmitButtonContainer"
              >
                <Button
                  onClick={submitButtonOnClick}
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    color: submitButtonColor,
                    backgroundColor: submitButtonBackgroundColor,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  {submitButtonlabel}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="dialogCancelButtonContainer"
              >
                <Button
                  onClick={handleClose}
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    color: cancelButtonColor,
                    textTransform: "none",
                  }}
                >
                  {cancelButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
}
