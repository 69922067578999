import {
  COUNT_ACTIVITY_ATTENDEES,
  COUNT_EVENT_ATTENDEES,
  NETWORKING_STATS,
  MEETINGS_INFO,
  MEETING_STATUS,
} from "../constants/actionTypesRedux/actionTypes";

export function countActivityAttendees(payload, channel) {
  let workshopguid = channel.split("workshop_commander:")[1];
  return {
    type: COUNT_ACTIVITY_ATTENDEES,
    channel: workshopguid,
    payload,
  };
}

export function countEventAttendees(payload) {
  return {
    type: COUNT_EVENT_ATTENDEES,
    payload,
  };
}

export function networkingStats(payload) {
  return {
    type: NETWORKING_STATS,
    payload,
  };
}

export function getMeetingsInfo(payload) {
  return {
    type: MEETINGS_INFO,
    payload,
  };
}

export function getMeetingStatus(payload) {
  return {
    type: MEETING_STATUS,
    payload: payload.meeting_id,
    status: payload.status,
    attendee: payload.attendee_id,
  };
}
