import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: "6px",
    width: "100%",
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EFEFF4",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#22D2B9",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function CustomizedProgressBars(props) {
  const { progressPercentage } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={progressPercentage} />
    </div>
  );
}
