import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

import "./CommentCard.css";

export default function CommentCard(props) {
  const {
    cardTitle,
    cardLabel,
    buttonOnClick,
    commentCardButtonLabel,
    maxLength,
    cardTitleColor,
    cardLabelColor,
    commentCardButtonLabelColor,
    commentCardButtonBackgroundColor,
  } = props;

  const [announcement, setAnnouncement] = useState("");
  const [announcementType, setAnnouncementType] = useState("alert");

  const handleChange = (event) => {
    setAnnouncementType(event.target.value);
  };

  const liveAnnouncementTextBoxHandleChange = (event) => {
    setAnnouncement(event.target.value);
  };

  const styledRadio = (
    <Radio size="small" style={{ color: commentCardButtonBackgroundColor }} />
  );

  return (
    <Paper>
      <Grid container item xs={12} sm={12} md={12}>
        <Grid container item xs={10} sm={11} md={11} className="titleContainer">
          <h1 className="commentCardTitle" style={{ color: cardTitleColor }}>
            {cardTitle}
          </h1>
        </Grid>
        <Grid container item xs={10} sm={12} md={12}>
          <span style={{ color: cardLabelColor }} className="commentCardLabel">
            {cardLabel}
          </span>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          className="textFieldContainer"
        >
          <TextField
            style={{ width: "100%" }}
            onChange={liveAnnouncementTextBoxHandleChange}
            multiline
            rows={5}
            variant="outlined"
            inputProps={{ maxLength: maxLength }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          className="buttonContainer"
        >
          <Grid item xs={12} sm={3} md={4} className="raddioButtons">
            <RadioGroup
              row
              aria-label="alert-type"
              name="alert-type"
              value={announcementType}
              onChange={handleChange}
              style={{ flexWrap: "nowrap" }}
            >
              <FormControlLabel
                value="alert"
                control={styledRadio}
                label="Alert"
                style={{ color: cardTitleColor, fontSize: 1 }}
              />
              <FormControlLabel
                value="informative"
                control={styledRadio}
                label="Informative"
                style={{ color: cardTitleColor, fontSize: 1 }}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={3} md={4} className="submitButton">
            <Button
              onClick={() => buttonOnClick(announcement, announcementType)}
              style={{
                width: "100%",
                borderRadius: "12px",
                color: commentCardButtonLabelColor,
                backgroundColor: commentCardButtonBackgroundColor,
                textTransform: "none",
              }}
              variant="contained"
            >
              {commentCardButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
