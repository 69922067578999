//login
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//Socket
export const COUNT_ACTIVITY_ATTENDEES = "count_workshop_attendees";
export const COUNT_EVENT_ATTENDEES = "count_event_attendees";
export const NETWORKING_STATS = "networking_stats";
export const MEETINGS_INFO = "meetings_info";
export const MEETING_STATUS = "meeting_status";
export const START_STREAM = 'start_stream';

//list of events
export const LIST_OF_EVENTS_LOADING = "LIST_OF_EVENTS_LOADING";
export const LIST_OF_EVENTS_ERROR = "LIST_OF_EVENTS_ERROR";
export const GET_LIST_OF_EVENTS = "GET_LIST_OF_EVENTS";
export const GET_LINKS_OF_EVENTS = "GET_LINKS_OF_EVENTS";

//Event
export const EVENT_LOADING = "EVENT_LOADING";
export const EVENT_ERROR = "EVENT_ERROR";
export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_ACTIVITIES = "GET_EVENT_ACTIVITIES";
export const GET_ACTIVITIES_LINKS = "GET_ACTIVITIES_LINKS";
export const GET_ACTIVITIES_SPEAKERS = "GET_ACTIVITIES_SPEAKERS";
export const GET_CHECKED_IN_ATTENDEES = "GET_CHECKED_IN_ATTENDEES";
export const GET_EVENT_BUSINESS_CONFERENCES = "GET_EVENT_BUSINESS_CONFERENCES";

//Meetings
export const MEETINGS_INFO_LOADING = "MEETINGS_INFO_LOADING";
export const MEETINGS_STATUS_LOADING = "MEETINGS_STATUS_LOADING";
export const MEETINGS_ERROR = "MEETINGS_ERROR";
export const GET_MEETINGS_LIVE = "GET_MEETINGS_LIVE";
export const GET_MEETING_FETCHED = "GET_MEETING_FETCHED";
export const CLEAN_MEETINGS_DATA = "CLEAN_MEETINGS_DATA";
